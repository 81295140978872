import React from 'react';

import { Layout } from '../../../../components/MenuLayout';
import { RemotePacsAdd } from '../../../../components/Hospital/Management/RemotePacs/RemotePacsAdd';

export default () => (
  <Layout type="settings">
    <RemotePacsAdd />
  </Layout>
);
